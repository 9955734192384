////////// jsCart for Reggaerecord.com
////////// copyright(c) 2005 Yasushi Kurobane

function Item(id, price, amount) {
	//alert('[Item]' + id + ", " + price + ", " + amount);
	this.id = id;
	this.amount = Number(amount);
	this.price = Number(price);
	//alert('[Item - out]');
}

Item.prototype.addAmount = function(amount) {
	this.setAmount(Number(this.amount) + Number(amount));
};

Item.prototype.setAmount = function(amount) {
	this.amount = Number(amount);
};


Item.prototype.subAmount = function(amount) {
	this.amount -= Number(amount);
};

function jsCart(max_buy_num, currency_header, currency_rate) {
	//alert("[jsCart.jsCart] max_buy_num = " + max_buy_num);
	this.items = [];
	this.totalPrice = 0;
	this.totalAmount = 0;
	this.max_items = 250;
	this.max_item_message = 'これ以上商品項目を追加できません。申し訳ありませんが、いったん清算してください。';
	this.no_more_stock_message = 'これ以上在庫がありません。';
	this.cookie_expire = 86400 * 365;
	this.max_buy_num = Number(max_buy_num);
	this.digits = { 'price_' : [], 'amount_' : [] };
	this.currency_info = { 'header':currency_header, 'rate':currency_rate }
	for (var i = 0; i < 8; i++) this.digits['price_'][i] = '';
	for (var i = 0; i < 4; i++) this.digits['amount_'][i] = '';
	/*window.onfocus = function () {
		if (myCart.totalAmount < 50) updateAll();
	}*/
}

jsCart.prototype.getItemNum = function() {
	return this.items.length;
};

jsCart.prototype.getTotalPrice = function() {
	this.totalPrice = 0;
	for (var i = 0; i < this.items.length; i++) {
		this.totalPrice += this.items[i].price * this.items[i].amount;
	}
	//alert('[getTotalPrice] totalPrice = ' + this.totalPrice);
	return this.totalPrice;
};

jsCart.prototype.getTotalAmount = function() {
	this.totalAmount = 0;
	for (var i = 0; i < this.items.length; i++) {
		//alert(this.items[i].amount);
		this.totalAmount += this.items[i].amount;
	}
	//alert('[getTotalAmount] totalAmount = ' + this.totalAmount);
	return this.totalAmount;
};

jsCart.prototype.getItemAmount = function(id) {
	for (var i = 0; i < this.items.length; i++) {
		if (this.items[i].id === id && this.items[i].amount !== null) {
			return this.items[i].amount;
		}
	}
};

jsCart.prototype.addItem = function(id, price, amount, noUpdate, max_num) {
	//alert('[jsCart.addItem] ' + id + ", " + price + ", " + amount + ", " + noUpdate);
	if (max_num == null) {
		max_num = this.max_buy_num;
	} else {
		max_num = Math.min(max_num, this.max_buy_num);
	}

	this.readFromCookie();
	if (noUpdate == null) {
		noUpdate = false;
	}
	var found = false;
	for (var i = 0; i < this.items.length; i++) {
		if (this.items[i].id == id) {
			//alert('found: id = ' + id);
			found = true;
			this.items[i].setAmount(Number(amount));
			if (this.items[i].amount > max_num)
				this.items[i].setAmount(max_num);
			break;
		}
	}

	if (!found) {
		if (this.items.length >= this.max_items) {
			alert(this.max_item_message);
			return this.items[i].amount;
		}
		var newItem = new Item(id, Number(price), Math.min(this.max_buy_num, Number(amount)));
		this.items.push(newItem);
	}
	if (!noUpdate) {
		this.updateTotal(true);
	}
	return this.items[i].amount;
};

jsCart.prototype.removeItem = function (id, amount, noUpdate) {
	//alert("[removeItem]");
	this.readFromCookie();
	if (noUpdate == null) {
		noUpdate = false;
	}
	var left;
	var found = false;
	for (var i = 0; i < this.items.length; i++) {
		if (this.items[i].id == id) {
			found = true;
			if (amount < 0) {
				this.items[i].amount = 0;
			} else {
				this.items[i].subAmount(Number(amount));
			}
			left = this.items[i].amount;
			if (this.items[i].amount == 0) {
				var newItems = [];
				for (var j = 0; j < this.items.length; j++) {
					if (j != i) {
						newItems.push(this.items[j]);
					}
				}
				this.items = newItems;
				//this.items.splice(i, 1);
			}
			break;
		}
	}
	if (found && (!noUpdate)) {
		this.updateTotal(true);
	} else {
		left = 0;
	}
	return left;
};

jsCart.prototype.getSubTotal = function (id) {
	for (var i = 0; i < this.items.length; i++) {
		if (this.items[i].id == id) {
			return this.items[i].price * this.items[i].amount;
		}
	}
	return 0;
};

jsCart.prototype.getAmount = function (id) {
	this.readFromCookie();
	for (var i = 0; i < this.items.length; i++) {
		if (this.items[i].id == id) {
			return this.items[i].amount;
		}
	}
	return 0;
};

jsCart.prototype.setAmount = function (id, price, amount, noUpdate, max_num) {
	//alert('[jsCart.setAmount] amount = ' + amount);
	this.readFromCookie();
	var found = false;
	if (max_num == null) {
		max_num = this.max_buy_num;
	} else {
		max_num = Math.min(max_num, this.max_buy_num);
	}

	var amount = Math.min(max_num, Number(amount));

	for (var i = 0; i < this.items.length; i++) {
		if (this.items[i].id == id) {
			if (amount > 0) {
				this.items[i].setAmount(amount);
			} else {
				var newItems = [];
				for (var j = 0; j < this.items.length; j++) {
					if (j != i) {
						newItems.push(this.items[j]);
					}
				}
				this.items = newItems;
				//this.items.splice(i, 1);
			}
			found = true;
		}
	}
	if (!found && amount > 0) {
		this.addItem(id, Number(price), Number(amount), true);
	}

	if (!noUpdate) {
			this.updateTotal(true);
	}

	return amount;
};

jsCart.prototype.clearAll = function() {
	//alert('clearAll');
	this.items = [];
	this.updateTotal(true);
};

jsCart.prototype.writeToCookie = function () {
	var myCookie = [];
	var d = new Date();

	for (var i = 0; i < this.items.length; i++) {
		var theItem = this.items[i];
		myCookie[i] = theItem.id + "-" + theItem.price + "-" + theItem.amount;
	}
	d.setTime(d.getTime() + this.cookie_expire);
	this.setCookie("cart", myCookie.join("_") + ";expires='" + d.toGMTString() + "'");
	//alert('[writeToCookie] - cart=' + myCookie.join("_") + ";expires='" + d.toGMTString() + "'");
};

jsCart.prototype.readFromCookie = function () {
	//alert('[readFromCookie]');
	this.items = [];
	var myCookie = this.getCookie("cart");
	if (myCookie == "") {
		this.setCookie("cart", '');
		return;
	}
	var rows = myCookie.split("_");
	for (var i = 0; i < rows.length; i++) {
		var fields = rows[i].split("-");
		var theItem = new Item(fields[0], Number(fields[1]), Number(fields[2]));
		this.items.push(theItem);
	}
};

jsCart.prototype.getCookie = function (key) {
	tmp1 = " " + document.cookie + ";";
	xx1 = xx2 = 0;
	while (xx1 < tmp1.length) {
		xx2 = tmp1.indexOf(";", xx1);
		tmp2 = tmp1.substring(xx1 + 1, xx2);
		xx3 = tmp2.indexOf("=");
		if (tmp2.substring(0, xx3) == key) {
			return(decodeURI(tmp2.substring(xx3 + 1, xx2 - xx1 - 1)));
		}
		xx1 = xx2 + 1;
	}
	return("");
};

jsCart.prototype.setCookie = function (key, value) {
	document.cookie = key + "=" + value + "; path=/";
};

jsCart.prototype.deleteCookie = function (key) {
	document.cookie = key + "=dummy; expires=Fri, 31-Dec-1999 23:59:59 GMT; path=/";
};

jsCart.prototype.updateTotal = function (w2c) {
	var my_total = this.getTotalPrice();
	document.getElementById('cart_indicator_price').innerHTML = my_total;
	document.getElementById('cart_indicator_amount').innerHTML = this.getTotalAmount();

	if (this.currency_info.rate) {
		document.getElementById('foreign_price').innerHTML =
			'(' +
			this.currency_info['header'] +
			numberFormat(my_total / this.currency_info['rate']) +
			')';
	}
	if (w2c) this.writeToCookie();
};

jsCart.prototype.updateNumbers = function (digits, value, img_name, prefix_name) {
	var digit;
	var file_name;

	//alert("[updateNumbers] digits = " + digits + ", value = " + value);
	var ended = false;
	for (var i = 1; i <= digits; i++) {
		digit = value % 10;
		value = Math.floor(value / 10);
		if (digit == 0 && value == 0 && i > 1) {
			if (!ended) {
				ended = true;
				file_name = prefix_name;
			} else {
				file_name = "/common_img/num_null.gif";
			}
		} else {
			file_name = "/common_img/num_" + String(digit) + ".gif";
		}
		if (this.digits[img_name][i] != file_name) {
			this.digits[img_name][i] = file_name;
			document[img_name + String(i)].src = file_name;
		}
	}
};

jsCart.prototype.showItemAmount = function (content) {
	for (var i = 0; i < document.forms.length; i++) {
		var f = document.forms[i];
		for (var j = 0; j < f.elements.length; j++) {
			var e = f.elements[j];
			if (e.type != 'text' || !e.name) continue;
			if (e.name == 'amount[]') e.value = '';
		}
	}

	for (var i = 0; i < this.items.length; i++) {
		this.updateItemAmount(this.items[i].id, this.items[i].amount);
	}
};

jsCart.prototype.updateItemAmount = function (id, amount, max_num) {
	if (Number(max_num) > 0) {
		max_num = Math.min(max_num, this.max_buy_num);
	} else {
		max_num = this.max_buy_num;
	}

	var $container = $('[data-item-id="' + selectorEscape(id) + '"]');

	$container.find(".amount-in-cart").val(Math.min(this.max_buy_num, Math.min(max_num, amount)));
}

jsCart.prototype.updateItemList = function () {
	if (!document.cartForm) {
		return;
	} else {
		if (cart_type != 1) return;
		var id_list = new Object();
		for (var i = 0; i < this.items.length; i++) {
			if (document.cartForm['item_' + this.items[i].id]) {
				document.cartForm['item_' + this.items[i].id].selectedIndex = this.items[i].amount - 1;
				if (updatePrices(this.items[i].id)) {
					id_list[this.items[i].id] = this.items[i].amount;
				} else {
					window.location.reload();
				}
			} else {
				window.location.reload();
			}
		}
		if (document.cartForm) {
			for (var i = 0; i < document.cartForm.elements.length; i++) {
				var e = document.cartForm.elements[i];
				if (e.name.indexOf('item_') != 0) continue;
				var the_id = e.name.substring(5);
				if (!id_list[the_id]) {
					window.location.reload();
				}
			}
		}
	}
};

function updateAll() {
	myCart.readFromCookie();
	myCart.updateTotal(false);
	myCart.showItemAmount();
	myCart.updateItemList();
}

function selectorEscape(val){
    return val.replace(/[ !"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}