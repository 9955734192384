$(document).ready(function () {
    var urlParams = new URLSearchParams(window.location.search);

    $(document).on('click', '.top_selector li a', function(e) {
        $(e.target).closest('.input-group').find('input[type=text]').val($(e.target).html());
    });

    $(document).on('click', '.btn-play', function(e) {
        window.open($(this).attr('href'), $(this).attr('target'), 'width=338,height=480,menubar=0,toolbar=0,scrollbars=0,location=0,status=0,resizable=yes');
        return false;
    });

    $(document).on('click', '.image_selector .btn-thumbnail', function(e) {
        var $me = $(e.target);
        var img_src = $me.attr('src');
        var src_360 = img_src.replace(/_60\.jpg/, '_360.jpg');
        $me.closest('.image_selector').find('img[name=main_image]').attr('src', src_360);
    });

    $(document).on('click', '.image_selector .magnify', function(e) {
        var $me = $(e.target);
        var src_main = $me.closest('.image_selector').find('img[name=main_image]').attr('src');
        var src_800 = src_main.replace(/_360\.jpg/, '_800.jpg');
        $('#image_overlay').css('visibility', 'visible');
        if ($('.image_selector .thumbnails').length === 0) {
            $('#image_overlay .btn-prev, #image_overlay .btn-next').css('display', 'none');
        }
        $('#image_overlay .image').html('<img src="' + src_800 + '" width="800" alt="" />');
    });

    $(document).on('click', '.slide_show .btn-next', function(e) {
        var $me = $(e.target);
        var $thumbnails = $('.image_selector .thumbnails');
        var src_800 = $('#image_overlay').find('img').attr('src');
        var $img_60 = $thumbnails.find('img[src$="' + src_800.replace(/_800\.jpg/, '_60.jpg') + '"]');
        var $img_next = $img_60.next('img');
        if ($img_next.length === 0) $img_next = $thumbnails.find('img:first-child');
        src_800 = $img_next.attr('src').replace(/_60\.jpg/, '_800.jpg');
        $('#image_overlay .image img').attr('src', $img_next.attr('src').replace(/_60\.jpg/, '_800.jpg'));
        return false;
    });

    $(document).on('click', '.slide_show .btn-prev', function(e) {
        var $me = $(e.target);
        var $thumbnails = $('.image_selector .thumbnails');
        var src_800 = $('#image_overlay').find('img').attr('src');
        var $img_60 = $thumbnails.find('img[src$="' + src_800.replace(/_800\.jpg/, '_60.jpg') + '"]');
        var $img_prev = $img_60.prev('img');
        if ($img_prev.length === 0) $img_prev = $thumbnails.find('img:last-child');
        $('#image_overlay .image img').attr('src', $img_prev.attr('src').replace(/_60\.jpg/, '_800.jpg'));
        return false;
    });

    $(document).on('click', '#image_overlay', function(e) {
        $('#image_overlay').css('visibility', 'hidden');
    });

    $('#form_payment_method input[name=payment_method]').on('click', function(e) {
        var payment_method = $(e.target.form).find('[name=payment_method]:checked').val();
        if (payment_method == '6') {
            //$(e.target.form).find('[name=batch_shipping]').val([ 'reserve' ]);
            $('.shipping_options').css('display', 'none');
        } else {
            //if ($('.shipping_options').css('display')== 'none') {
            //  $(e.target.form).find('[name=batch_shipping]').attr('checked', false);
            //}
            $('.shipping_options').css('display', 'block');
        }
    });

    $(document).on('click', '#btn-to_detailed_search', function(e) {
        showSearchForm();
    });

    $(document).on('click', '#btn-to_simple_search', function(e) {
        hideSearchForm();
    });

    $(document).on('click', '#btn-requery', function(e) {
        showSearchForm();

        var $search_form = $('#multi_search_form');
        var $param_form = $(e.target).closest('form');
        var type = $param_form.find('input[name=search_type]').val();

        switch(type) {
            case 'multi':
                var $inputs = $param_form.find('input').not('[name="search_type"]');
                $inputs.each(function() {
                    var name = $(this).attr('name');
                    $search_form.find('[name="' + name + '"]').val($(this).val());
                });
                break;

            default:
                var keyword = $param_form.find('input[name=keyword]').val();
                $search_form.find('[name="' + type + '"]').val(keyword);
        }
    });

    $(document).on('keypress', '#cart_content :input', function(e) {
        $('#cart_content :checkbox').prop('checked', false);
    });

    function erasePage($target)
    {
        if (!$('#content')[0]) return false;

        $('body').hide();
        window.setTimeout(function() {
            $('body').show();
            $('input').each(function() {
                $(this).blur();
            });
            $('#multi_search_form').find('input').each(function() {
                $(this).val('');
            });
            $('#breadcrumbs').remove();
            $('#content').remove();
            $('#footer').remove();
            $('#header_tabs li').removeClass('active');
            if ($target.closest('ul').attr('id') == 'header_tabs') {
                $target.closest('li').addClass('active');
            }
        }, 15);
    }

    $(document).on('click', 'a.btn-nd', function(e) {
        var $form = $(e.target).closest('form');
        var $target = $(e.target).closest('div.section');
        $('body').addClass('wait');

        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize(),
            timeout: 30000,

            success: function(html, textStatus, xhr) {
                $('body').removeClass('wait');
                $target.html(html);
            },

            error: function(xhr, textStatus, e) {
                //alert('[getNarrowDown]Time Out');
            }
        });
        return false;
    });

    $(document).on('click', '.chk-inventory', function (e) {
        var $chk = $(e.target);
        var $container = $chk.closest('.buy_buttons');
        var data = {
            XDEBUG_SESSION_START : 'netbeans-xdebug',
            id : $chk.data('id'),
            status : $chk.data('status')
        };

        $.ajax({
            url: '/api/update_inventory.php',
            type: 'post',
            data: data,
            dataType: 'json',
            timeout: 30000,

            success: function (data) {
                if ('error' in data) {
                    alert (data.error);
                } else {
                    $container.html(data.buy);
                    $('.item_operations form').replaceWith(data.actions);
                }
            }
        });
    });

    $(document).on('click', 'button[name=search_zip]', function (e) {
        var $zip = $(':input[name=zip]');
        var data = {
            zip : $zip.val()
        };

        $.ajax({
            url : '/api/zip_to_address.php',
            type : 'get',
            data : data,
            dataType : 'json',
            timeout : 10000,

            success : function (data) {
                $(':input[name=zip]').val(data.zip);
                $(':input[name=pref]').val(data.addr1);
                $(':input[name=address_1]').val(data.addr2);
                $(':input[name=address_2]').val(data.addr3).focus();
            },

            error : function (XMLHttpRequest, textStatus, errorThrown) {
                alert(XMLHttpRequest.responseText);
            }
        });

        return false;
    });

    $(document).on('click', 'a.search_category', function (e) {
        $target = $(e.target);
        $form = $target.closest('form');
        $form.attr('action', $target.attr('href'));
        $form.submit();

        return false;
    })

    $(document).on('mousedown selectstart contextmenu', 'img', function () {
        return false;
    });

    function updateCart($container, amount, to) {
        var item_id = $container.data('item-id');
        var price = $container.data('price');
        var max_num = $container.data('max-num');
        var style = $container.data('style');

        if (to === undefined) {
            to = 'Cart';
        }

        if (myCart.getItemAmount(item_id) >= max_num) {
            alert(myCart.no_more_stock_message);
            return false;
        }

        if (to == 'Cart') {
            myCart.updateItemAmount(item_id, amount = myCart.addItem(item_id, price, amount, false, max_num, false));
        }

        $.ajax({
            url: '/api/update_cart.php',
            type: 'get',
            dataType: 'json',
            data: {
                to : to,
                item : item_id,
                amount : amount,
                style : style
            },
            success: function(json) {
                if (json.message != '') {
                    alert(json.message);
                }
                $container.replaceWith($(json.html));
                myCart.writeToCookie();
            }
        });
    }

    $(document).on('click', '.buy_buttons a.btn-buy', function(e) {
        var $input = $(e.target);
        var $container = $input.closest('.buy_buttons');
        return updateCart($container, Number($container.find('input.amount-in-cart').val()) + 1);
    });

    $(document).on('change', '.buy_buttons input.amount-in-cart', function(e) {
        var $input = $(e.target);
        return updateCart($input.closest('.buy_buttons'), $input.val());
    });

    $(document).on('click', '.buy_buttons a.btn-wait', function(e) {
        return updateCart($(e.target).closest('.buy_buttons'), 1, 'Want');
    });

    $(document).on('submit', '#narrow_down_99', function(e) {
        $form = $(e.target);
        var value = 0;
        $form.find(':checkbox').each(function() {
            value += $(this).prop('checked') * Math.pow(2, $(this).val() - 1);
        });
        $form.find('[name=narrow_down_99]').val(value > 0 ? value : null);
    });

    $('#form_payment_method input[name=payment_method]').on('click', function(e) {
        var payment_method = $(e.target.form).find('[name=payment_method]:checked').val();
        if (payment_method == '6') {
            //$(e.target.form).find('[name=batch_shipping]').val([ 'reserve' ]);
            $('.shipping_options').css('display', 'none');
        } else {
            //if ($('.shipping_options').css('display')== 'none') {
            //  $(e.target.form).find('[name=batch_shipping]').attr('checked', false);
            //}
            $('.shipping_options').css('display', 'block');
        }
    });

    $(document).on('change', '#shipping_config input[name=shipping_method], #country_selector', function(e) {
        var country_name = $('#shipping_config').find('[name=country]').val();
        var shipping_method = $('#shipping_config').find('[name=shipping_method]:checked').val();
        var data = {
            country: country_name,
            shipping_method: shipping_method
        };

        data.type = 'main';
        $.ajax({
            url: "/api/shipping_info.php",
            type: "get",
            data: data,
            success: function(html) {
                $('#shipping_info').html(html);
            }
        });

        data.type = 'po_bo';
        $.ajax({
            url: "/api/shipping_info.php",
            type: "get",
            data: data,
            success: function(html) {
                $('#shipping_info_po_bo').html(html);
            }
        });
    });

    function getSuperGenres() {
        if ($('#ajaxy_super_genres').length === 0) {
            return;
        }

        $.ajax({
            url: '/api/super_genres.php',
            type: 'get',
            data: { XDEBUG_SESSION_START: 'netbeans-xdebug' },
            timeout: 30000,

            success: function(html, textStatus, xhr) {
                $('#ajaxy_super_genres').append(html);
                //getItem();
            },

            error: function(xhr, textStatus, e) {
                //alert('[getNarrowDowns]Time Out');
            }
        });
    }

    getSuperGenres();

    function getNarrowDowns() {
        if ($('#ajaxy_narrow_downs').length === 0) {
            return;
        }

        $.ajax({
            url: '/api/narrow_downs.php',
            type: 'get',
            data: urlParams.toString(),
            timeout: 30000,

            success: function(html, textStatus, xhr) {
                $('#ajaxy_narrow_downs').append(html);
                //getItem();
            },

            error: function(xhr, textStatus, e) {
                //alert('[getNarrowDowns]Time Out');
            }
        });
    }

    getNarrowDowns();

    $('#form_shipping_method').ajaxForm({
        target : 'body'
    }).submit(function() {
        $(this).ajaxSubmit();
        return false;
    });

    $('#form_shipping_method input[name=shipping_method]').on('change', function(e) {
        $(e.target.form).submit();
    });

    var slider = $('#slider8').bxSlider({
        controls: false,
    });

    $('.thumbs a').click(function(){
        var thumbIndex = $('.thumbs a').index(this);
        slider.goToSlide(thumbIndex);
        $('.thumbs a').removeClass('pager-active');
        $(this).addClass('pager-active');
        return false;
    });
    $('.thumbs a:first').addClass('pager-active');
});

function search(type, keyword) {
    var key_list = [ 'artist', 'title', 'format_code', 'rhythm', 'label', 'recent', 'section_web_order', 'song', 'track_id' ];
    var multi = false;

    keyword = keyword.replace(/\[quote\]/g, "'");
    keyword = keyword.replace(/\[dquote\]/g, '"');

    if ($('#multi_search').css('display') == 'none') {
        if (false) { //document.simple_search_form.keyword.value) {
            keyword = $('#simple_search_form [name=keyword]').val() + " " + keyword;
            if ($('#field_' + type).prop('selected') != 'selected') {
                type = 'all';
            }
        }
        //showSearchForm();
        if ($('#field_' + type).length === 0) {
            $('#simple_search_form [name=keyword]').val(keyword);
            $('#simple_search_form [name=search_type]').val(type);
            $form = $(document.simple_search_form);
            $form.attr('action', search_url);
            $form.submit();
            return;
        }
    } else {
        for (i = 0; i < key_list.length; i++) {
            if (key_list[i] == type) continue;
            if (document.multi_search_form[key_list[i]] == null) continue;
            if (document.multi_search_form[key_list[i]].value != '') {
                multi = true;
                break;
            }
        }
    }
    if (multi) {
        document.multi_search_form[type].value = keyword;
        document.multi_search_form.submit();
    } else {
        var theURL = search_url + "?search_type=" + type;
        if (keyword != '') {
            theURL += "&keyword=" + encodeURIComponent(keyword);
        }
        document.location.href = theURL;
    }
}

function writeContactInfo(a, b, c, d) {
    var i = 'mai';
    var j = '@';
    var k;
    var l;
    var m;
    switch (b) {
        case 1:
            k = 'reggaerecord';
            l = '.com';
            break;

        case 2:
            k = 'dubstore';
            l = '.co.jp';
            break;
    }
    i += 'lto:';
    a += j + k + l;
    if (c) {
        m = c;
    } else {
        m = a;
    }
    if (d) {
        n = "?subject=" + d;
    } else {
        n = "";
    }
    document.write('<a href="' + i + a + n + '">' + m + '</a>');
}

function getRecommended(web_id, rec_start) {
    $.ajax({
        url: '/ajax/recommend_list.php',
        type: 'get',
        data: {
            w: web_id,
            s: rec_start,
            c: location.href,
            l: site_language
        },
        success: function(html, textStatus, xhr) {
            $('#recommend_list').html(html);
        },
    });
}
